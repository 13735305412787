import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const NoteBook: React.FC = () => {
    const navigate = useNavigate();

    const navigateToWords = () => {
        navigate('/words');
    };

    return (
    <div>
        <h1>Welcome to the Zhihan's Notebook</h1>
        <button onClick={navigateToWords}>Go to Words</button>
    </div>
    );
};

export default NoteBook;